 
body{    font-family: "Poppins", sans-serif !important;
  }
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

ul.links{ float: right; padding: 10px 0 0 0 ;  margin: 0px;}
ul.links li{ float: left; list-style: none; padding: 10px 15px;  }
ul.links li a{ text-decoration: none; color: #000;}
.pageSection{ padding: 40px 0 0 50px;}
.pageSection img{ width: 100%;}
.panel-msg{  margin-top: 50px;}
.blogs .col-md-12{ padding-left: 0px;}
/* .blagsec{ margin-bottom: 40px;} */
.blogdetails{ padding:0  30px 30px 30px; width: 800px;  font-family: "Poppins", sans-serif !important;}
.blogdetails h2{ font-size: 18px; margin-bottom: 30px; color: #bccf00;}
.blogdetails img{ width: 50%;}
.blagsec_title{ font-size: 26px;      line-height: 35px;  display: block; font-family: "Poppins", sans-serif;
  font-weight: 600;  margin-bottom:25px; text-decoration: none; color: #000;} 
.detailsbtn{ background: none; border: none; text-align: left; font-family: "Poppins", sans-serif !important;}
.allserchbox input{ width: 42%; font-size: 15px; border-radius: 30px; border: 1px solid rgba(0, 0, 0, 0.12); padding: 10px 20px;      height: 40px;}
.allserchbox button{ margin-left: 7px;  font-size: 15px;}
.allserchbox{ margin-bottom: 30px;     padding-top: 30px;}
.searchbtn{ border-radius: 25px !important; padding: 5px 18px !important; height:40px; }
.blogdetails h3{ margin-bottom: 30px; font-size:25px; font-weight: 700;}
.backbtn{ margin-top: 30px; display: block;}
.backbtn a{ display: inline-block; padding: 10px 30px; border-radius:5px;  background: #39A1FF; color: #fff; text-decoration: none;}
ul.links li a{ font-size: 19px;}
ul.links li:last-child{ padding: 28px 0 0 0;}
.banner{ background: #2e151b; padding: 80px 0;}
/* .blogsinner{ border-top: 1px  solid #ccc;} */
.aythername{ display: flex; margin-bottom: 40px;}
.aythername img{ border-radius:50% ; border: 1px solid #ccc;}
.aythername h2{ margin-bottom: 0px; font-size: 15px;} 
.rightsec{ margin-top:15px; }
.rightsec h2{ color: #000; padding-bottom: 5px; padding-left: 20px; font-size: 18px;  font-weight: bold; color:#0d6efd; }
.rightsec h4{ color: #000; padding-bottom: 5px; padding-left: 20px; font-size: 15px;}
.allserchbox h1{ color: #0F1B29; margin-right: 60px;   font-family: "Poppins", sans-serif !important;   font-weight: 700; font-size: 38px;}
/* .detailsbtn:hover{ border-bottom: 1px solid #ccc;} */
.allserchbox{ display: inline-flex;}
.rightsec{ background:#fff;  border-radius: 30px; padding: 30px; }
.rightsec h4{ font-size: 20px; font-weight: 700; margin: 0 0 20px 0; padding: 0px;}
.headtitle{  position: absolute; font-size: 38px; font-weight: 700; left: 50%;}
.blogsinner{  background: #F4FBFF;      padding-top: 10px;}

.blogdetails .rightsec{ background: none; padding: 0px;     margin-top: 10px;}
.blogdetails .rightsec h2{ font-size: 21px; color: #000; }
.blogssec{ background: #fff; border-radius: 30px; padding: 30px;  margin-top: 30px;}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.header {
  background-color: white;
  color: black;
  display: flex;
  width: 123;
  height: 40;
  text-decoration: none;
  

}
.header ul{
  margin: 0px;
  padding: 0px;
}
.header li{
  float: left;
  list-style: none;
  margin-right: 30px;

}
.header li  a{
  text-decoration: none;
color: #000;
font-size: 19px;
  
}

.panel-msg img{
  width: 100%;
}
.panel-msg1 img{
  width: 100%;
}
.panel-msg2 img{
  width: 100%;
}
.panel-msg3 img{
  width: 100%;
}
.panel-msg4 img{
  width: 100%;
}
.panel-msg5 img{
  width: 100%;
}
.panel-msg6 img{
  width: 100%;
}
.panel-msg7 img{
  width: 100%;
}
.panel-msg8 img{
  width: 100%;
}


.card {

  padding: 15px;
min-height: 320px;
}


.blogs{ padding-left: 0px !important; padding-right: 0px !important;   } 

     
.panel-msg{ margin-top: 60px;}
.panel-msg1 img, .panel-msg img, .panel-msg2 img, .panel-msg3 img,.panel-msg4 img,.panel-msg5 img,.panel-msg6 img,.panel-msg7 img,.panel-msg8 img{ border-radius: 6px;}
.footer{ background: #000;  text-align: center;    padding: 35px 0;}
.footer ul{ margin: 0px; padding: 0px;}
.footer ul li{     list-style: none;
  display: inline-flex;
  padding-right: 40px;
  font-size: 17px;
}
.footer ul li a{ color: #fff; text-decoration: none;}
.footer ul li a:hover{ color: #ffdd00;display: flex;}
.tesiminoal{ background: #f1f1f1; padding: 20px 0; margin: 50px 0 0px 0;}
.heading h3 {
  color: #7f7f7f;
  text-align: center;
  margin-top: 70px;
}
.formo {
  display: flex;
  align-items: center;
  justify-content: center;
}




.formo label{ color:#7f7f7f ;}
.same {
  margin-left: 30px;
  color: white;
}
.formo textarea {
  width: 100%;
  margin-top: 20px;
}
.textareahead {
  width: 45%;
margin: 25px auto;
}
.btn {

  margin-top: 0px;

}
.form { margin:40px 0 0 0;}
.form  placeholder{ color: #fff !important;}




.react-share__ShareButton{
  margin-right: 7px;
}



.comment-section {
  margin-top: 20px;
}

.comment-input {
  margin-bottom: 20px; margin-top: 25px;
}

.comment-input textarea {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid  #e7e9de;
  border-radius: 10px;
  resize: vertical;
}

.comment-input button {
  background: #39A1FF;     display: inline-block;
  padding:10px 30px;
  border-radius: 5px; border: none;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;
}

.comment-input button:hover {
  background-color: #0056b3;
}

.comment {
  margin-bottom: 0px;

  /* background-color: #f9f9f9; */

}

.commentmainsec {
  margin-bottom: 30px;
  padding: 30px;
  /* background-color: #f9f9f9; */
  border-radius: 30px;
  background: #fff;
}

.commentmainsec h4{ margin-bottom: 20px;}

.comment p:first-child {
  margin-bottom: 10px;
}

.comment button {
  padding: 4px 8px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}
.comment-input{ background: #fff;     border-radius: 30px;
  padding: 30px;}
.comment button:hover {
  background-color: #c82333;
}
.btncolor{ background: #39A1FF;      font-size: 15px;  margin-bottom: 20px;  margin-top: 15px;  display: inline-block;
  padding:10px 20px;
  border-radius: 5px; border: none;
  color: #fff;
  text-decoration: none;}

  .comment-section {
    margin-top: 20px;
    /* background: #f9f9f9; margin-bottom: 20px; */
    padding:20px 20px 20px 0;  border-radius: 5PX;
}

.comment-input input{ margin-bottom: 20px; margin-right: 10px;     border-radius: 10px;  border:1px solid  rgba(0, 0, 0, 0.12); font-size: 14px;     padding: 10px 16px; }
.comment-input textarea{   margin-bottom: 15px; }
.bloguser{  color: #000; font-size: 17px;  font-weight: 700;    margin-bottom: 9px; display: block;}
.comment{  font-size: 14px; }
.error{ color: #ff0000; margin-top: 10px;}
.comment span{ float: left;}
.comment p{ display: block;      padding-left: 72px;   clear: both;}
.days{ padding: 2px 0 0 10px;}
.comment h4{ font-size: 21px; padding-bottom: 10px;}
.comment-input h4{   font-size: 21px; padding-bottom: 10px;}
.comment-input button{ margin-right: 15px;}

.ql-align-center {
  text-align: center !important;
}

.ql-align-left {
  text-align: left !important;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-justify {
  text-align: justify !important;
}
.ql-editor{
  line-height: 20px !important;
}

.blogs{ background: #F4FBFF; }
ul.links li:first-child{ margin: 0px; padding: 0px;}
.leftsec{ background: #F4FBFF;}
.blagsec{ background: #fff; width: 98%; border-radius: 30px;     padding: 30px;}
.blagsecinner p{ font-size: 16px;}
.blagsecinner span {
  width: 243px; margin-top: 15px;
  display: inline-flex; font-size: 15px;
}
.autherinner{ width: auto; float: left; margin-top: 0px !important; color: #0074DD;}
.blogsinner h2{  font-weight: 700; padding-top: 20px;}
.timesec{ display: inline-block; float: none !important; font-weight: normal; font-size: 14px; padding-left: 20px;}
